import { useEffect, useContext } from "react";
import {
  ActionTypes,
  UpdateDispatchContext,
  useUpdateState,
} from "@ugg/shared/components/SummonerProfiles/SummonerProfileUpdateReducer";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { useFilterManager } from "@ugg/shared/components/FilterManager/FilterManager";
import { ChampionStatsTable } from "./ChampionStatsTable";
import { ArenaChampionStatsTable } from "./ArenaChampionStatsTable";
import { BasicChampionPerformance, usePlayerStatistics } from "@ugg/shared/api/requests/summoner-profiles/player-statistics";
import { RoleC, RoleS, normalizeRole } from "@ugg/shared/utils/role-helpers";
import { getArenaModeType, QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { validParamsProfile } from "@ugg/shared/query-params/valid-params-profile";
import { QUEUE_TYPE_ID_MAP, QUEUE_TYPE_RANKED } from "@ugg/shared/query-params/filter-options-profile/champion-stats";
import { INTERNAL_CURRENT_SEASON } from "@ugg/shared/utils/season-helpers";
import { SUMMONER_PROFILE_PAGES } from "@ugg/shared/pages/summoner-profile-pages";
import { ChampionStatsFilterManager } from "@ugg/shared/components/SummonerProfiles/champion-stats/ChampionStatsFilterManager";

export function SummonerChampionStats() {
  const dispatch = useContext(UpdateDispatchContext);
  const context = useSummonerProfileContext();
  const { riotUserName, riotTagLine, region } = context;
  const { isUpdatingProfile } = useUpdateState();
  const { filters } = useFilterManager(SUMMONER_PROFILE_PAGES.CHAMPION_STATS, validParamsProfile);

  const queueType = QUEUE_TYPE_ID_MAP[filters.queueType as keyof typeof QUEUE_TYPE_ID_MAP];
  const isRanked = QUEUE_TYPE_RANKED.includes(filters.queueType);

  const hasRole = [
    "all_ranked",
    QueueTypeS.RANKED_SOLO,
    QueueTypeS.RANKED_FLEX,
    QueueTypeS.NORMAL_DRAFT,
    QueueTypeS.QUICKPLAY,
    QueueTypeS.SWIFTPLAY,
    QueueTypeS.NEXUS_BLITZ,
  ].includes(filters.queueType);

  const { data, loading, error, refetch, variables } = usePlayerStatistics(
    riotUserName,
    riotTagLine,
    region,
    {
      queueType,
      seasonId: parseInt(filters.season),
      role: RoleC.convertToInt(hasRole ? normalizeRole(filters.role, RoleS.ALL) : RoleS.ALL),
    },
    {
      ssr: false,
      fetchPolicy: isUpdatingProfile ? "network-only" : "cache-first",
    },
  );

  const { fetchPlayerStatistics } = data || {};
  const champions = (fetchPlayerStatistics || []).reduce((acc, curr) => {
    return acc.concat(curr.basicChampionPerformances);
  }, [] as BasicChampionPerformance[]);

  useEffect(() => {
    dispatch({
      type: ActionTypes.ADD_UPDATES,
      payload: { playerChampionStats: refetch },
    });
  }, [JSON.stringify(variables)]);

  return (
    <div className="w-full">
      <ChampionStatsFilterManager hasRole={hasRole} className="mb-[24px]" />
      {loading ? (
        <div className={"rounded-[3px] bg-purple-400 w-full min-h-screen"}>
          <div className="spinthatshit-loader">
            <div className="spinner my-[200px]" />
          </div>
        </div>
      ) : filters.queueType === QueueTypeS.ARENA ? (
        <ArenaChampionStatsTable data={champions} arenaModeType={getArenaModeType(filters.patch)} />
      ) : (
        <ChampionStatsTable isRanked={isRanked} data={champions} hideLP={parseInt(filters.season) !== INTERNAL_CURRENT_SEASON} />
      )}
    </div>
  );
}
