import { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { MediaQuery, useResponsive } from "@outplayed/responsive";
import { getRoleSVG } from "@ugg/shared/utils/role-helpers";
import { getTierColor, getProBuildRankName } from "@ugg/shared/utils/rank-helpers";
import { getRiotAssetsContext, getS13RankUrl, getIcon } from "@outplayed/riot-assets";
import { SummonerSpell, Rune } from "@outplayed/tooltips";
import EditableFullRuneTree from "@ugg/shared/components/common/RuneTrees/EditableFullRuneTree";
import { ReactComponent as TriangleArrowDown } from "svg/triangle-arrow-down.svg";
import { LiveGameTags } from "./LiveGameTags";
import { ChampionBan } from "@ugg/shared/components/SummonerProfiles/live-game/ChampionBan";
import { getSeasonLabel, INTERNAL_CURRENT_SEASON, INTERNAL_PREV_SEASON } from "@ugg/shared/utils/season-helpers";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";
import { HeaderBullet } from "@ugg/shared/components/common/HeaderBullet";
import { QueueTypeS, hasRoles, hasBans } from "@ugg/shared/utils/queue-type-helpers";
import { LiveGameData } from "@ugg/shared/api/requests/summoner-profiles/live-game";
import { getLiveGameWinRateColor, LiveGamePlayerPlus } from "@ugg/shared/utils/live-game-helpers";

interface LiveGameTableProps {
  queueType: LiveGameData["getLiveGame"]["gameType"];
  teamA: LiveGamePlayerPlus[];
  teamB: LiveGamePlayerPlus[];
}

export function LiveGameTable(props: LiveGameTableProps) {
  const { queueType, teamA, teamB } = props;
  const noBans = !hasBans(queueType);

  return (
    <div className="w-full">
      {!noBans && (
        <div className="flex justify-between flex-wrap gap-[10px] px-[18px] py-[12px] max-xs:px-[12px] border-b-[1px] border-purple-500 bg-[#1D1D3E]">
          <div className="flex gap-[12px] max-xs:gap-[8px]">
            {teamA.map((player, index) => (
              <ChampionBan
                key={index}
                className="!border-accent-blue-400 max-xs:w-[24px] max-xs:h-[24px]"
                championId={player.banId}
              />
            ))}
          </div>
          <div className="flex gap-[12px] max-xs:gap-[8px]">
            {teamB.map((player, index) => (
              <ChampionBan
                key={index}
                className="border-accent-red-500 max-xs:w-[24px] max-xs:h-[24px]"
                championId={player.banId}
              />
            ))}
          </div>
        </div>
      )}
      <Team teamId={100} teamData={teamA} queueType={queueType} />
      <Team teamId={200} teamData={teamB} queueType={queueType} />
    </div>
  );
}

const tableGridClassNames = `
  grid
  grid-cols-[33fr_17fr_17fr_17fr_7fr_9fr]
  max-sm:grid-cols-[38fr_13fr_13fr_10fr_12fr]
  max-xs:grid-cols-[38fr_13fr_13fr]
`;

interface PlayerProps {
  index: number;
  teamId: number;
  player: LiveGamePlayerPlus;
  queueType: QueueTypeS;
}

function Player(props: PlayerProps) {
  const context = useSummonerProfileContext();
  const { getRuneKeystoneId, getChampionImg } = getRiotAssetsContext();
  const { realRiotUserName, realRiotTagLine, region } = context;
  const { currentBreakpoint } = useResponsive();
  const [showRunes, setShowRunes] = useState(false);
  const { index, player, queueType } = props;
  const keystone = getRuneKeystoneId(player.summonerRuneData);
  const { tier, rank, lp, wins, losses } = player.currentSeasonRankScore || {};
  const { tier: prevTier } = player.previousSeasonRankScore || {};
  const seasonWinRate = Math.round(player.seasonWinRate * 100);
  const championWinRate = Math.round(player.championWinRate * 100);
  const isMobile = ["MOBILE_SMALL", "MOBILE_MEDIUM"].includes(currentBreakpoint);

  const noRoles = !hasRoles(queueType);

  return (
    <div key={index} className={"text-lavender-50 text-[12px] max-sm:text-[11px]"}>
      <div
        className={classNames(
          tableGridClassNames,
          `
            h-[56px] max-sm:h-[46px]
            hover:bg-purple-100 hover:bg-opacity-50 hover:cursor-pointer
          `,
        )}
        onClick={() => setShowRunes(!showRunes)}
      >
        <div className="flex items-center justify-center pl-[18px] max-sm:pl-[12px] max-xs:pl-[8px] overflow-hidden">
          {!noRoles && (
            <div className="flex flex-col items-center justify-center mr-[18px] max-md:mr-[12px] max-xs:mr-[8px]">
              <div
                className={classNames(
                  "flex items-center justify-center [&_svg]:flex-none [&_svg]:w-[20px] max-xs:[&_svg]:w-[12px]",
                  { "opacity-30": !player.onRole },
                )}
              >
                {getRoleSVG(player.currentRole)}
              </div>
              <span className="mt-[2px] text-[8px] leading-[1] max-xs:hidden">{player.onRole ? "MAIN" : "OFF"}</span>
            </div>
          )}
          <div className={`flex-none mr-[6px] w-[30px] h-[30px] rounded-[3px] bg-purple-200`}>
            <div className="flex items-center justify-center w-full h-full rounded-[3px] overflow-hidden">
              <img className="w-full h-full scale-[1.1]" src={getChampionImg(player.championId)} />
            </div>
          </div>
          <div className="flex gap-[2px]">
            <div className="flex flex-col gap-[2px]">
              <div className="flex items-center justify-center w-[14px] h-[14px] rounded-[2px] bg-purple-100 overflow-hidden">
                <SummonerSpell className="w-full h-full" spellId={player.summonerSpellA} />
              </div>
              <div className="flex items-center justify-center w-[14px] h-[14px] rounded-[2px] bg-purple-100 overflow-hidden">
                <SummonerSpell className="w-full h-full" spellId={player.summonerSpellB} />
              </div>
            </div>
            <div className="flex flex-col gap-[2px]">
              <div className="flex items-center justify-center w-[14px] h-[14px] rounded-[2px] bg-purple-100 overflow-hidden">
                <Rune className="w-full h-full" runeId={keystone} />
              </div>
              <div className="flex items-center justify-center w-[14px] h-[14px] rounded-[2px] bg-purple-100 overflow-hidden">
                <Rune className="w-full h-full" runeId={player.summonerRuneB} />
              </div>
            </div>
          </div>
          <div className="flex-1 pr-[2px] ml-[18px] max-sm:ml-[12px] max-xs:ml-[8px] overflow-hidden">
            <div
              className={classNames("truncate text-white text-[12px] font-bold", {
                "!text-accent-orange-700": realRiotUserName === player.riotUserName && realRiotTagLine === player.riotTagLine,
              })}
            >
              <Link
                className={classNames("!text-inherit truncate hover:underline")}
                to={getProfileOverviewUrl(region, player.riotUserName, player.riotTagLine)}
              >
                {player.riotUserName} #{player.riotTagLine}
              </Link>
            </div>
            <MediaQuery min="TABLET" max="DESKTOP_LARGE">
              <div className="mt-[2px]">
                <LiveGameTags championId={player.championId} tags={player.playerTags} />
              </div>
            </MediaQuery>
            <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
              <div className="h-[15px]">
                {tier && rank && (
                  <span>
                    <strong style={{ color: getTierColor(tier) }}>{getProBuildRankName(tier, rank, true)}</strong>
                    <span className="text-lavender-300"> / </span>
                    <strong>{lp || 0} LP</strong>
                  </span>
                )}
              </div>
            </MediaQuery>
          </div>
        </div>
        <div className="flex items-center justify-center max-sm:hidden">
          <div className="grid w-[120px] grid-cols-[30px_1fr] gap-[9px] ml-[6px]">
            <div className="flex items-center justify-center">
              <img className="flex-none w-[30px]" src={getS13RankUrl(tier || "unranked", true)} />
            </div>
            <div className="flex items-center">
              {!tier || !rank ? (
                <span>Unranked</span>
              ) : (
                <span>
                  <strong style={{ color: getTierColor(tier) }}>{getProBuildRankName(tier, rank, true)}</strong>
                  <span className="text-lavender-300"> / </span>
                  <span className="font-medium">{lp} LP</span>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          {player.seasonGamesPlayed === 0 ? (
            <span>-</span>
          ) : (
            <div className="flex flex-col items-center justify-center px-[12px] max-sm:px-0 w-full text-center whitespace-nowrap">
              <span>
                <strong style={{ color: getLiveGameWinRateColor(seasonWinRate) }}>{seasonWinRate}%</strong>
                <span className="text-lavender-300 max-sm:hidden"> / </span>
                <br className="sm:hidden" />
                <span className="font-medium">
                  {wins || 0}W {losses || 0}L
                </span>
              </span>
              <div className="mt-[5px] w-full max-w-[120px] h-[6px] rounded-[3px] bg-accent-purple-700 overflow-hidden max-sm:hidden">
                <div
                  style={{
                    width: `${seasonWinRate}%`,
                    height: "100%",
                    backgroundColor: getLiveGameWinRateColor(seasonWinRate),
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center justify-center text-center">
          {player.championGamesPlayed === 0 ? (
            <span>-</span>
          ) : (
            <span>
              <strong style={{ color: getLiveGameWinRateColor(championWinRate) }}>{championWinRate}%</strong>
              <span className="text-lavender-300 max-sm:hidden"> / </span>
              <br className="sm:hidden" />
              <span>
                {player.championWins || 0}W {player.championLosses || 0}L
              </span>
            </span>
          )}
        </div>
        <div className="flex items-center justify-center max-xs:hidden">
          {prevTier ? (
            <img className="flex-none w-[30px] max-sm:w-[24px]" src={getS13RankUrl(prevTier || "unranked", true)} />
          ) : (
            <span>-</span>
          )}
        </div>
        <div className="flex items-center justify-center max-xs:hidden">
          <div
            className={
              "flex items-center justify-center px-[6px] py-[4px] rounded-[2px] bg-purple-100 hover:bg-accent-purple-700 hover:cursor-pointer"
            }
            onClick={() => setShowRunes(!showRunes)}
          >
            <strong className="text-white text-[10px]">RUNES</strong>
            <TriangleArrowDown className="w-[5px] ml-[5px] [&_path]:fill-white" />
          </div>
        </div>
      </div>
      {showRunes && (
        <div className="p-[18px] max-sm:p-[12px] max:xs-[8px]">
          <div className="rounded-[3px] bg-purple-200">
            <div className="p-[18px] max-sm:p-[12px] max:xs-[8px] border-b-[1px] border-purple-500">
              <HeaderBullet className="mb-[12px]">
                <div>Player Tags</div>
              </HeaderBullet>
              <div className="">
                <LiveGameTags championId={player.championId} tags={player.playerTags} />
              </div>
            </div>
            <div className="p-[18px] max-sm:p-[12px] max:xs-[8px]">
              <HeaderBullet className="mb-[12px]">
                <div>Runes</div>
              </HeaderBullet>
              <EditableFullRuneTree
                className="!justify-center gap-[36px] max-xs:gap-[12px] [&_.rune-tree\_header]:bg-purple-400"
                recRunes={{
                  primary_style: player.summonerRuneA,
                  sub_style: player.summonerRuneB,
                  active_perks: player.summonerRuneData,
                }}
                statShards={{ active_shards: player.summonerRuneData.slice(player.summonerRuneData.length - 3) }}
                isMobile={isMobile}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

interface TeamProps {
  teamId: number;
  teamData: LiveGamePlayerPlus[];
  queueType: QueueTypeS;
}

function Team(props: TeamProps) {
  const { teamId, teamData, queueType } = props;
  const teamSide = teamId === 100 ? "Blue" : "Red";

  const currSeason = getSeasonLabel(INTERNAL_CURRENT_SEASON);
  const prevSeason = getSeasonLabel(INTERNAL_PREV_SEASON);

  return (
    <div>
      <div
        className={classNames(
          tableGridClassNames,
          `
            h-[56px] max-sm:h-[46px] items-center
            text-[14px] font-bold max-sm:text-[12px] max-sm:font-medium
            bg-purple-400 text-white
          `,
        )}
      >
        <div
          className={classNames("text-[14px] pl-[18px] max-sm:pl-[12px]", {
            "text-accent-blue-400": teamId === 100,
            "text-accent-orange-500": teamId === 200,
          })}
        >
          <strong>{teamSide} Team</strong>
        </div>
        <div className="table-header_season-rank text-center">S{currSeason} Rank</div>
        <div className="text-center">
          <span className="max-xs:hidden">S{currSeason} WR</span>
          <span className="xs:hidden">
            S{currSeason}
            <br />
            WR
          </span>
        </div>
        <div className="text-center">
          <span className="max-xs:hidden">Champion WR</span>
          <span className="xs:hidden">
            Champ
            <br />
            WR
          </span>
        </div>
        <div className="text-center max-xs:hidden">S{prevSeason}</div>
        <div className="text-center max-xs:hidden"></div>
      </div>
      <div className="[&>*:nth-child(odd)]:bg-[#1D1D3E] [&>*:nth-child(even)]:bg-purple-400">
        {teamData.map((player, index) => (
          <Player key={index} index={index} teamId={teamId} player={player} queueType={queueType} />
        ))}
      </div>
    </div>
  );
}
