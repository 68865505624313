import { useState, useEffect, useMemo, useContext } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { ActionTypes, UpdateDispatchContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileUpdateReducer";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { usePlayerStatistics, BasicChampionPerformance } from "@ugg/shared/api/requests/summoner-profiles/player-statistics";
import { formatSummonerChampionStats } from "@ugg/shared/api/data-parser/summoners/summoner-champion-stats";
import { getKdaColorBestChampsClassName, calculateKDA } from "@ugg/shared/utils/kda";
import { getWinRateBestChampsColorClassName } from "@ugg/shared/utils/win-rates";
import { QUEUE_TYPE_ID_MAP, QUEUE_TYPE_RANKED } from "@ugg/shared/query-params/filter-options-profile/champion-stats";
import PlayedChampionsFilters from "./PlayedChampionsFilters";
import { LPGainArrows } from "@ugg/shared/components/SummonerProfiles/overview/lp/LPGainArrows";
import { HeaderBullet } from "@ugg/shared/components/common/HeaderBullet";
import { INTERNAL_CURRENT_SEASON, getSeasonLabel } from "@ugg/shared/utils/season-helpers";
import { getProfileChampionStatsUrl } from "@ugg/shared/routes/app-routes";
import { validParamsProfile } from "@ugg/shared/query-params/valid-params-profile";

const EmptyBlock = (props: { seasonId: number; filters: { queueType: string } }) => {
  const { seasonId, filters } = props;
  const queueTypeOptions = validParamsProfile["champion-stats"]["queueType"];

  const queueTypeLabel =
    filters.queueType === "all_ranked"
      ? "Ranked"
      : (queueTypeOptions.find((option) => option.value === filters.queueType) || {}).label;

  const realSeason = getSeasonLabel(INTERNAL_CURRENT_SEASON);

  return <div className="played-champions_empty">{`No S${realSeason} ${queueTypeLabel} games played.`}</div>;
};

const ChampionPerformance = (props: { isRanked: boolean; data: BasicChampionPerformance }) => {
  const { getChampionName, getNormalizedChampionName, getChampionImgFromSprite } = getRiotAssetsContext();
  const { data, isRanked } = props;

  const { assists, championId, cs, deaths, kills, totalMatches, wins, lpAvg } = data;

  const kdaRatio = calculateKDA(kills || 0, deaths || 0, assists || 0);
  const kdaColorClassName = getKdaColorBestChampsClassName(kdaRatio);
  const avgKills = Math.round(((kills || 0) / totalMatches) * 10) / 10;
  const avgDeaths = Math.round(((deaths || 0) / totalMatches) * 10) / 10;
  const avgAssists = Math.round(((assists || 0) / totalMatches) * 10) / 10;
  const winRate = Math.round(((wins || 0) / totalMatches) * 100);

  const championNameId = getNormalizedChampionName(championId);

  return (
    <div className="champion-performance">
      <div className="champion-face">{getChampionImgFromSprite(championId, { size: 38 })}</div>
      <div className="champion-stats">
        <div className={"champion-stats_col champion-stats_col-1 " + (isRanked ? "" : "champion-col-center")}>
          <div className={"champion-name " + (isRanked ? "" : "champion-name-center")}>{getChampionName(championId)}</div>
          {isRanked && (
            <div className="lp">
              <LPGainArrows className="" lpAvg={lpAvg} />
            </div>
          )}
        </div>
        <div className="champion-stats_col champion-stats_col-2">
          <div className={classnames("kda-ratio", kdaColorClassName)}>
            <span>{kdaRatio}</span>
            <span> KDA</span>
          </div>
          <div className="kda-split">
            <span>{avgKills}</span>
            <span className="slash"> / </span>
            <span className="avg-deaths">{avgDeaths}</span>
            <span className="slash"> / </span>
            <span>{avgAssists}</span>
          </div>
        </div>
        <div className="champion-stats_col champion-stats_col-3">
          <div className="win-rate">
            <div className={getWinRateBestChampsColorClassName(winRate)}>{`${winRate}%`}</div>
          </div>
          <div className="total-games">{totalMatches} games</div>
        </div>
      </div>
    </div>
  );
};

interface PlayedChampionsContainerProps {}

export function PlayedChampionsContainer(props: PlayedChampionsContainerProps) {
  const dispatch = useContext(UpdateDispatchContext);
  const context = useSummonerProfileContext();
  const { riotUserName, riotTagLine, region } = context;
  const defaultFilters = validParamsProfile["champion-stats"].default;
  const [filtersChanged, setFiltersChanged] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  const { data, loading, error, refetch, variables } = usePlayerStatistics(
    riotUserName,
    riotTagLine,
    region,
    { queueType: QUEUE_TYPE_ID_MAP[filters.queueType as keyof typeof QUEUE_TYPE_ID_MAP] },
    { fetchPolicy: !filtersChanged ? "cache-first" : "network-only" },
  );

  useEffect(() => {
    setFilters(defaultFilters);
  }, [riotUserName, riotTagLine, region]);

  useEffect(() => {
    dispatch({
      type: ActionTypes.ADD_UPDATES,
      payload: { playedChampions: refetch },
    });
  }, [JSON.stringify(variables)]);

  const formattedData = useMemo(() => {
    const { fetchPlayerStatistics } = data || {};
    const champions = (fetchPlayerStatistics || []).reduce<BasicChampionPerformance[]>((acc, curr) => {
      return acc.concat(curr.basicChampionPerformances);
    }, []);
    return formatSummonerChampionStats(champions).sort((a, b) => {
      const matchDiff = a.played.totalMatches - b.played.totalMatches;
      if (matchDiff > 0) {
        return -1;
      } else if (matchDiff < 0) {
        return 1;
      } else {
        const winRateDiff = a.winRate - b.winRate;
        if (winRateDiff > 0) {
          return -1;
        } else if (winRateDiff < 0) {
          return 1;
        }
        return 0;
      }
    });
  }, [data]);

  let content = null;
  if (loading) {
    content = (
      <div className="played-champions_loading">
        <div className="spinthatshit-loader">
          <div className="spinner"></div>
        </div>
      </div>
    );
  } else if (formattedData.length > 0) {
    let rankedFilter = QUEUE_TYPE_RANKED.includes(filters.queueType);
    const champStatsUrl = getProfileChampionStatsUrl(region, riotUserName, riotTagLine);
    content = (
      <div
        className={classnames(
          "champion-list sb-size-[14px] sb-track-border-radius-[0px] sb-padding-bg-purple-400 sb-track-bg-[transparent]",
        )}
      >
        {formattedData.map((champion, index) => (
          <div
            key={champion.championId}
            className={classnames("contents", {
              "md:!hidden": index >= 7,
              "max-md:!hidden": index >= 5,
            })}
          >
            <ChampionPerformance data={champion} isRanked={rankedFilter} />
          </div>
        ))}
        <Link
          to={champStatsUrl}
          className={classnames("show-more-btn", {
            "md:!hidden": formattedData.length < 7,
            "max-md:!hidden": formattedData.length < 5,
          })}
        >
          See More Champions &#38; Seasons
        </Link>
      </div>
    );
  } else {
    content = <EmptyBlock seasonId={variables?.seasonId} filters={filters} />;
  }

  return (
    <div className="played-champions">
      <div className="played-champions_header">
        <HeaderBullet>
          <span className="leading-none">Champion Stats</span>
        </HeaderBullet>
        <PlayedChampionsFilters filters={filters} setFilters={setFilters} setFiltersChanged={setFiltersChanged} />
      </div>
      {content}
    </div>
  );
}
