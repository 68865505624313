import Highcharts from "highcharts";
import { RankS } from "@ugg/shared/utils/rank-helpers";

type VALID_BAND_TIERS =
  | RankS.IRON
  | RankS.BRONZE
  | RankS.SILVER
  | RankS.GOLD
  | RankS.PLATINUM
  | RankS.EMERALD
  | RankS.DIAMOND
  | RankS.MASTER
  | RankS.GRANDMASTER
  | RankS.CHALLENGER;

type PlotBandTuple = [[number, number], string];
const PlotBandTiers: { [key in VALID_BAND_TIERS]: PlotBandTuple[] } = {
  [RankS.IRON]: [
    [[0, 100], "I4"],
    [[100, 200], "I3"],
    [[200, 300], "I2"],
    [[300, 400], "I1"],
  ],
  [RankS.BRONZE]: [
    [[400, 500], "B4"],
    [[500, 600], "B3"],
    [[600, 700], "B2"],
    [[700, 800], "B1"],
  ],
  [RankS.SILVER]: [
    [[800, 900], "S4"],
    [[900, 1000], "S3"],
    [[1000, 1100], "S2"],
    [[1100, 1200], "S1"],
  ],
  [RankS.GOLD]: [
    [[1200, 1300], "G4"],
    [[1300, 1400], "G3"],
    [[1400, 1500], "G2"],
    [[1500, 1600], "G1"],
  ],
  [RankS.PLATINUM]: [
    [[1600, 1700], "P4"],
    [[1700, 1800], "P3"],
    [[1800, 1900], "P2"],
    [[1900, 2000], "P1"],
  ],
  [RankS.EMERALD]: [
    [[2000, 2100], "E4"],
    [[2100, 2200], "E3"],
    [[2200, 2300], "E2"],
    [[2300, 2400], "E1"],
  ],
  [RankS.DIAMOND]: [
    [[2400, 2500], "D4"],
    [[2500, 2600], "D3"],
    [[2600, 2700], "D2"],
    [[2700, 2800], "D1"],
  ],
  [RankS.MASTER]: [[[2800, 10000], "M"]],
  [RankS.GRANDMASTER]: [[[2800, 10000], "GM"]],
  [RankS.CHALLENGER]: [[[2800, 10000], "C"]],
};

const getHighTierColor = (tier: string) => {
  const tiers: { [key: string]: ReturnType<typeof generatePlotBand> } = {
    [RankS.MASTER]: generatePlotBand(
      PlotBandTiers[RankS.MASTER][0][0],
      PlotBandTiers[RankS.MASTER][0][1],
      "#A4584E",
      "rgba(164, 88, 78, 0.5)", // #A4584E
    ),
    [RankS.GRANDMASTER]: generatePlotBand(
      PlotBandTiers[RankS.GRANDMASTER][0][0],
      PlotBandTiers[RankS.GRANDMASTER][0][1],
      "#CD4545",
      "rgba(90, 58, 58, 0.5)", // "#5A3A3A"
    ),
    [RankS.CHALLENGER]: generatePlotBand(
      PlotBandTiers[RankS.CHALLENGER][0][0],
      PlotBandTiers[RankS.CHALLENGER][0][1],
      "#F4C874",
      "rgba(97, 89, 76, 0.5)", // "#61594C"
    ),
  };

  return tiers[tier?.toLowerCase()];
};

export const getFilteredPlotbands = (min: number, max: number, currentTier: string, maxTier: string) => {
  const highestTier = currentTier !== maxTier ? maxTier : currentTier;
  return getPlotbands(highestTier).filter((plotband) => {
    if (plotband.from !== undefined && plotband.to !== undefined) {
      return plotband.from <= max + 99 && plotband.to >= min - 99;
    }
    return false;
  });
};

function generatePlotBand(
  range: [number, number],
  label: string,
  labelColor: string,
  bgColor: string,
): Highcharts.YAxisPlotBandsOptions {
  return {
    from: range[0],
    to: range[1],
    color: bgColor,
    label: {
      text: label,
      style: {
        color: labelColor,
      },
      verticalAlign: "bottom",
      y: -5,
    },
  };
}

const getPlotbands = (maxTier: string): Array<ReturnType<typeof generatePlotBand>> => [
  // Iron
  ...PlotBandTiers[RankS.IRON].map((x) =>
    generatePlotBand(
      x[0],
      x[1],
      "#7D6E71",
      "rgba(58, 55, 56, 0.5)", // "#3A3738"
    ),
  ),
  // Bronze
  ...PlotBandTiers[RankS.BRONZE].map((x) =>
    generatePlotBand(
      x[0],
      x[1],
      "#A76C54",
      "rgba(88, 67, 58, 0.5)", // "#58433A"
    ),
  ),
  // Silver
  ...PlotBandTiers[RankS.SILVER].map((x) =>
    generatePlotBand(
      x[0],
      x[1],
      "#80989D",
      "rgba(83, 87, 88, 0.5)", // "#535758"
    ),
  ),
  // Gold
  ...PlotBandTiers[RankS.GOLD].map((x) =>
    generatePlotBand(
      x[0],
      x[1],
      "#CD8837",
      "rgba(90, 75, 58, 0.5)", // "#5A4B3A"
    ),
  ),
  // Platinum
  ...PlotBandTiers[RankS.PLATINUM].map((x) =>
    generatePlotBand(
      x[0],
      x[1],
      "#25ACD6",
      "rgba(46, 80, 90, 0.5)", // "#2E505A"
    ),
  ),
  // Emerald
  ...PlotBandTiers[RankS.EMERALD].map((x) => generatePlotBand(x[0], x[1], "#149C3A", "rgba(38, 66, 46, 0.5)")), // #26422E
  // Diamond
  ...PlotBandTiers[RankS.DIAMOND].map(
    (x) => generatePlotBand(x[0], x[1], "#8141EB", "rgba(88, 67, 124, 0.5)"), // "#58437C"
  ),
  // Due to the variability in bands for master/grandmaster/challenger, we cannot
  // have hardcoded bands for these tiers. Instead, we will just have the background
  // color of the graph reflect their current tier.
  ...(getHighTierColor(maxTier) ? [{ ...getHighTierColor(maxTier) }] : []),
];

function generatePlotLine(value: number, color: string): Highcharts.YAxisPlotLinesOptions {
  return {
    value,
    color,
    width: 1,
    dashStyle: "Dash",
    zIndex: 9999,
  };
}

export const getPlotLines = (maxTier: string) => [
  // Iron
  ...PlotBandTiers[RankS.IRON].map((x) => generatePlotLine(x[0][0], "#7D6E71")),
  // Bronze
  ...PlotBandTiers[RankS.BRONZE].map((x) => generatePlotLine(x[0][0], "#7B5B4E")),
  // Silver
  ...PlotBandTiers[RankS.SILVER].map((x) => generatePlotLine(x[0][0], "#717879")),
  // Gold
  ...PlotBandTiers[RankS.GOLD].map((x) => generatePlotLine(x[0][0], "#816545")),
  // Platinum
  ...PlotBandTiers[RankS.PLATINUM].map((x) => generatePlotLine(x[0][0], "#25ACD6")),
  // Emerald
  ...PlotBandTiers[RankS.EMERALD].map((x) => generatePlotLine(x[0][0], "#149C3A")),
  // Diamond
  ...PlotBandTiers[RankS.DIAMOND].map((x) => generatePlotLine(x[0][0], "#8141EB")),
  // Master
  ...(maxTier?.toLowerCase() === RankS.MASTER ? [generatePlotLine(PlotBandTiers[RankS.MASTER][0][0][0], "#A4584E")] : []),
  // Grandmaster
  ...(maxTier?.toLowerCase() === RankS.GRANDMASTER
    ? [generatePlotLine(PlotBandTiers[RankS.GRANDMASTER][0][0][0], "#CD4545")]
    : []),
  // Challenger
  ...(maxTier?.toLowerCase() === RankS.CHALLENGER ? [generatePlotLine(PlotBandTiers[RankS.CHALLENGER][0][0][0], "#F4C874")] : []),
];
