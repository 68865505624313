import { useResponsive } from "@outplayed/responsive";
import ItemTimeline from "@ugg/shared/components/common/ItemTimeline";
import SkillPath from "@ugg/shared/components/common/Skills/SkillPath";
import EditableFullRuneTree from "@ugg/shared/components/common/RuneTrees/EditableFullRuneTree";
import { SummonerMatch } from "@ugg/shared/api/requests/summoner-profiles/single-match";

interface BuildSectionProps {
  data: SummonerMatch;
  winConditionClass: string;
}

export default function BuildSection(props: BuildSectionProps) {
  const { currentBreakpoint } = useResponsive();
  const { data, winConditionClass } = props;
  const { historicalData, matchSummary } = data || {};
  const { championId, version } = matchSummary;
  const { itemPath, primaryStyle, runes, skillPath, statShards, subStyle } = historicalData || {};

  const showRunes = primaryStyle && subStyle && runes.every((id) => id > 0);

  return (
    <div className="build-section">
      {!!showRunes && (
        <div className="section runes-section">
          <div className="content-section_header rune-section-header">Runes</div>
          <div className="build-content build-content_center">
            <EditableFullRuneTree
              className="mx-auto"
              recRunes={{
                active_perks: runes,
                primary_style: primaryStyle,
                sub_style: subStyle,
              }}
              statShards={{ active_shards: statShards }}
              patch={version}
              isMobile={["MOBILE_SMALL", "MOBILE_MEDIUM"].includes(currentBreakpoint)}
            />
          </div>
        </div>
      )}
      <div className="section skill-section">
        <div className="content-section_header skill-section-header">Skill Order</div>
        <div className="build-content build-content_center overflow-x-auto overflow-y-hidden">
          <SkillPath championId={championId} skillOrder={skillPath || []} />
        </div>
      </div>
      <div className="section item-path">
        <div className="content-section_header item-section-header">Items</div>
        <div className="build-content build-content_center">
          <ItemTimeline items={itemPath} winConditionClass={winConditionClass} patch={version} spriteSize={30} />
        </div>
      </div>
    </div>
  );
}
