import { Link } from "react-router-dom";
import { useResponsive } from "@outplayed/responsive";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";
import { normalizePatch } from "@ugg/shared/utils/patch-helpers";
import { calculateKDA, getKdaColor } from "@ugg/shared/utils/kda";
import { thousandCondense } from "@ugg/shared/utils/math";
import ItemsList from "@ugg/shared/components/common/ItemsList";
import { SummonerMatchData, SummonerMatch } from "@ugg/shared/api/requests/summoner-profiles/single-match";
import { ArenaAugment } from "@outplayed/tooltips";
import { arenaTeams, mergeArenaTeams } from "@ugg/shared/utils/arena-helpers";

interface ArenaPostGameContainerProps {
  data: SummonerMatchData["match"];
}

export function ArenaPostGameContainer(props: ArenaPostGameContainerProps) {
  const { data } = props;
  const { currentBreakpoint } = useResponsive();
  const isMobileSmall = ["MOBILE_MEDIUM", "MOBILE_SMALL"].includes(currentBreakpoint);

  const { historicalData, matchSummary } = data || {};
  const { postGameData } = historicalData || {};
  const { regionId, version, riotUserName, riotTagLine, teamA, teamB } = matchSummary || {};
  const normalizedPatch = normalizePatch(version);

  const finalTeamPlayerRows = mergeArenaTeams(teamA, teamB);
  const placements = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"];

  return (
    <div className="expanded-match_arena-post-game">
      <div className="arena-dropdown-container">
        {finalTeamPlayerRows.map((team, index1: number) => (
          <div className="team-container" key={index1}>
            <div className="stats-header">
              <div className="team-info">
                <span className={`placement _${index1}`}>{placements[index1]}</span>
                <div className="team-logo">{arenaTeams[team[0].teamId - 1].icon}</div>
                <span className="team-name">{`(Team ${arenaTeams[team[0].teamId - 1].name})`}</span>
              </div>
              {!isMobileSmall && (
                <div className="indv-stats">
                  <span className="kda">KDA</span>
                  <span className="damage">Damage</span>
                  <span className="taken">Taken</span>
                  <span className="gold">Gold</span>
                  <span className="items">Items</span>
                </div>
              )}
            </div>
            {team.map((player, index2: number) => (
              <PlayerRow
                key={index1 * 2 + index2}
                index={index1 * 2 + index2}
                data={postGameData}
                riotUserName={player.riotUserName}
                riotTagLine={player.riotTagLine}
                totalTeams={teamA.length + teamB.length}
                isMainSummoner={riotUserName === player.riotUserName && riotTagLine === player.riotTagLine}
                normalizedPatch={normalizedPatch}
                regionId={regionId}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

const PlayerRow = (props: {
  data: SummonerMatch["historicalData"]["postGameData"];
  riotUserName: string;
  riotTagLine: string;
  index: number;
  totalTeams: number;
  isMainSummoner: boolean;
  normalizedPatch: string;
  regionId: string;
}) => {
  const { data, riotUserName, riotTagLine, index, totalTeams, isMainSummoner, normalizedPatch, regionId } = props;
  const { getWorkingPatch, getChampionImg, getChampionName } = getRiotAssetsContext();
  const patch = getWorkingPatch();
  const color = index >= Math.floor(totalTeams / 2) ? "red" : "blue";
  const matchResult = index >= Math.floor(totalTeams / 2) ? "match_lose" : "match_win";
  const findPlayerAugment = data.find((player) => player.riotUserName === riotUserName && player.riotTagLine === riotTagLine);
  const augments = findPlayerAugment ? findPlayerAugment.augments : [0, 0, 0, 0];
  const summonerProfile = isMainSummoner ? "main" : "";
  const { currentBreakpoint } = useResponsive();
  const isMobileSmall = ["MOBILE_MEDIUM", "MOBILE_SMALL"].includes(currentBreakpoint);

  let playerData = { assists: 0, championId: 0, damage: 0, damageTaken: 0, deaths: 0, gold: 0, items: [0], kills: 0, level: 0 };
  let maxDamageGiven = 0;
  let maxDamageTaken = 0;
  for (const info in data) {
    if (data[info].damage > maxDamageGiven) {
      maxDamageGiven = data[info].damage;
    }
    if (data[info].damageTaken > maxDamageTaken) {
      maxDamageTaken = data[info].damageTaken;
    }
    if (data[info].riotUserName === riotUserName && data[info].riotTagLine === riotTagLine) {
      playerData = data[info];
    }
  }

  function orderItems(list: number[]) {
    let tempZero: number[] = [];
    let tempNum: number[] = [];

    for (const num in list.slice(0, 6)) {
      if (list[num] === 0) {
        tempZero.push(list[num]);
      } else {
        tempNum.push(list[num]);
      }
    }
    return [...tempNum, ...tempZero, ...list.slice(6, 7)];
  }

  const itemList = orderItems(playerData.items);

  const kdaRatio = calculateKDA(playerData.kills || 0, playerData.deaths || 0, playerData.assists || 0);
  return !isMobileSmall ? (
    <div className={`player-row _${index} ${color} ${summonerProfile}`}>
      <div className="summoner">
        <div className="champion">
          <div className="champion-face">
            <img src={getChampionImg(playerData.championId)} alt={getChampionName(playerData.championId)} />
            <div className="champion-level">{playerData.level}</div>
          </div>
        </div>
        <div className="augments">
          {augments.map((augment, index) => (
            <div key={index} className={`augment-holder ${matchResult} ${summonerProfile}`}>
              <ArenaAugment className="augment-image" augmentId={augment} patch={normalizedPatch} />
            </div>
          ))}
        </div>
        <div className="summoner-info">
          <Link
            className="summoner-name"
            title={riotUserName}
            to={getProfileOverviewUrl(regionId, riotUserName, riotTagLine)}
            onClick={(e) => e.stopPropagation()}
          >
            {riotUserName}
          </Link>
          {/* <div className="summoner-rank">Holder</div> */}
        </div>
      </div>
      <div className="right-half">
        <div className="kda">
          <div className="kda-totals">
            {playerData.kills}
            <span className="slash">/</span>
            {playerData.deaths}
            <span className="slash">/</span>
            {playerData.assists}
          </div>
          <div className="kda-ratio">
            <span style={{ color: getKdaColor(kdaRatio) }}>{kdaRatio}</span> <span className="faded">KDA</span>
          </div>
        </div>
        <div className="damage">
          <div className="amount">{playerData.damage.toLocaleString()}</div>
          <div className="damage-bar">
            <div className={`top-bar ${color}`} style={{ width: `${(playerData.damage / maxDamageGiven) * 100}%` }} />
            <div
              className={`grey-bar ${color} ${summonerProfile}`}
              style={{ width: `${(1 - playerData.damage / maxDamageGiven) * 100}%` }}
            />
          </div>
        </div>
        <div className="taken">
          <div className="amount">{playerData.damageTaken.toLocaleString()}</div>
          <div className="damage-bar">
            <div className={`top-bar ${color}`} style={{ width: `${(playerData.damageTaken / maxDamageTaken) * 100}%` }} />
            <div
              className={`grey-bar ${color} ${summonerProfile}`}
              style={{ width: `${(1 - playerData.damageTaken / maxDamageTaken) * 100}%` }}
            />
          </div>
        </div>
        <div className="gold">{thousandCondense(playerData.gold)}</div>
        <div className="items">
          <div className="main-items">
            <ItemsList items={itemList.slice(0, 6)} winConditionClass={matchResult} patch={patch} dim={16} />
            <ItemsList items={[itemList[itemList.length - 1]]} winConditionClass={matchResult} patch={patch} dim={16} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={`player-row _${index} ${color} ${summonerProfile} mobile-med-small`}>
      <div className="left-half">
        <div className="summoner">
          <div className="champion">
            <div className="champion-face">
              <img src={getChampionImg(playerData.championId)} alt={getChampionName(playerData.championId)} />
              <div className="champion-level">{playerData.level}</div>
            </div>
          </div>
          <div className="augments">
            {augments.map((augment, index) => (
              <div key={index} className={`augment-holder ${matchResult} ${summonerProfile}`}>
                <ArenaAugment className="augment-image" augmentId={augment} patch={normalizedPatch} />
              </div>
            ))}
          </div>
          <div className="summoner-info">
            <Link
              className="summoner-name"
              title={riotUserName}
              to={getProfileOverviewUrl(regionId, riotUserName, riotTagLine)}
              onClick={(e) => e.stopPropagation()}
            >
              {riotUserName}
            </Link>
            <div className="kda">
              <div className="kda-totals">
                {playerData.kills}
                <span className="slash">/</span>
                <span className="deaths">{playerData.deaths}</span>
                <span className="slash">/</span>
                {playerData.assists}
              </div>
              <div className="kda-ratio">
                <span style={{ color: getKdaColor(kdaRatio) }}>{kdaRatio}</span> <span className="faded">KDA</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-half">
        <div className="main-items">
          <ItemsList items={itemList} winConditionClass={matchResult} patch={patch} dim={16} />
        </div>
        <div className="other-stats">
          <div className="gold">{thousandCondense(playerData.gold)}</div>
          <div className="damage-bar">
            <div
              className={`damage-percent ${matchResult}`}
              style={{ width: `${(playerData.damage / maxDamageGiven) * 100}%` }}
            />
            <div className="amount">{thousandCondense(playerData.damage)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
