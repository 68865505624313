import { useState } from "react";
import "moment-duration-format";
import { useSummonerMatch } from "@ugg/shared/api/requests/summoner-profiles/single-match";
import { ExpandedMatchCardNav } from "../ExpandedMatchCardNav";
import { ArenaPostGameContainer } from "./ArenaPostGameContainer";
import BuildSection from "../BuildSection";

import { getProfileMatchLoaderUrl } from "@ugg/shared/routes/app-routes";

interface ExpandedArenaMatchCardProps {
  matchId: number;
  version: string;
  regionId: string;
  riotUserName: string;
  riotTagLine: string;
  winConditionClass: string;
}

export default function ExpandedArenaMatchCard(props: ExpandedArenaMatchCardProps) {
  const { matchId, version, regionId, riotUserName, riotTagLine, winConditionClass } = props;
  const [chosenTab, setChosenTab] = useState("post-game");
  const { data, loading, error } = useSummonerMatch(regionId, riotUserName, riotTagLine, version, matchId.toString());

  if (!data || loading || error) {
    return (
      <div className="expanded-match-card-container expanded-match-card-container_loading">
        <div className="loader">
          <div className="spinthatshit-loader">
            <div className="spinner" />
          </div>
        </div>
      </div>
    );
  }

  const tabsArray = [
    {
      id: "post-game",
      title: "Post Game",
      active: chosenTab === "post-game",
      onClick: (id: string) => setChosenTab(id),
      component: <ArenaPostGameContainer data={data?.match} />,
    },
    {
      id: "item-buid",
      title: "Item Build",
      active: chosenTab === "item-buid",
      onClick: (id: string) => setChosenTab(id),
      component: <BuildSection data={data?.match} winConditionClass={winConditionClass} />,
    },
  ];

  const fullMatchURL = getProfileMatchLoaderUrl(regionId, riotUserName, riotTagLine, version, matchId);

  return (
    <div className="expanded-match-card-container">
      <ExpandedMatchCardNav tabs={tabsArray} matchUrl={fullMatchURL} />
      {tabsArray.find((tab) => tab.id === chosenTab)?.component}
    </div>
  );
}
